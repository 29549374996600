import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

export default function RniForm() {

  const handleInput = (event) => {
    const inputValue = event.target.value;
    // Utilizamos una expresión regular para eliminar todos los caracteres no numéricos
    const numericValue = inputValue.replace(/[^0-9]/g, '');
    // Limitamos la longitud a 8 caracteres
    const limitedValue = numericValue.slice(0, 8);
    // Actualizamos el valor del campo de texto
    event.target.value = limitedValue;
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleInput(event);
    }
  };

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Ingrese RNI:
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            required        
            id="rni"
            name="rni"
            label="RNI"
            fullWidth
            autoComplete="given-name"
            variant="standard"
            onInput={handleInput}
            onKeyDown={handleKeyDown}
          />
        </Grid>
        
      </Grid>
    </React.Fragment>
  );
}
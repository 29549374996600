import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import RniForm from './RniForm.js';
import ResultadoConsulta from './ResultadoConsulta.js';

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://consulta.citior.org.bo/">
        CITI ORURO
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const steps = ['Ingreso de RNI', 'Resultado Consulta'];

function getStepContent(step, apiResult) {
  switch (step) {
    case 0:
      return <RniForm />;
    case 1:
      return <ResultadoConsulta apiResult={apiResult}/>;
    default:
      throw new Error('Unknown step');
  }
}


export default function Consulta() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [apiResult, setApiResult] = React.useState(null); // Nuevo estado

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const consultar = async () => {
    if (activeStep === 0) {
      // Obtener el valor del campo RNI
      const rniValue = document.getElementById('rni').value; // Asegúrate de que el campo tenga un ID
      
      if (rniValue === ''){
        alert('Por favor, ingrese un valor en el campo RNI.');
      } else{

        {getStepContent(activeStep, apiResult)}

      // Realizar la solicitud a la API
      try {
        console.log('Consultando el RNI:'+rniValue);
        
        const response = await fetch('https://consulta.citior.org.bo/api/colegiado/'+rniValue);
        //const response = await fetch('http://localhost:4000/colegiado/'+rniValue);
                
        if (response.ok) {
          const data = await response.json();
          setApiResult(data);
          setActiveStep(activeStep + 1);
        } else {
          // Manejar errores de solicitud
          console.error('Error al consultar la API');
        }
      } catch (error) {
        console.error('Error al consultar la API', error);
      }


      }

      
    } else {
      setActiveStep(activeStep - 1);
    }
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar
        position="absolute"
        color="default"
        elevation={0}
        sx={{
          position: 'relative',
          borderBottom: (t) => `1px solid ${t.palette.divider}`,
        }}
      >
        <Toolbar>
          <Typography variant="h6" color="inherit" noWrap>
            Colegio de Ingenieros en Tecnologías de la Información - Oruro
          </Typography>
        </Toolbar>
      </AppBar>
      <Container component="main" maxWidth="md" sx={{ mb: 4 }}>
        <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
          <Typography component="h1" variant="h4" align="center">
            Consulta afiliación Colegiado
          </Typography>
          <Typography variant="body2" align="center" style={{ fontStyle: 'italic' }}>
            Última actualización de base de datos: 09-11-2024 23:06:00
          </Typography>
          <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          {
            <React.Fragment>
              {getStepContent(activeStep,apiResult)}
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  variant="contained"                  
                  onClick={consultar}
                  sx={{ mt: 3, ml: 1 }}
                >
                  {activeStep === steps.length - 1 ? 'Reiniciar' : 'Verificar'}
                </Button>
              </Box>

            </React.Fragment>
          }
        </Paper>
        <Copyright />
      </Container>
    </React.Fragment>
  );
}
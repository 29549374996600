import logo from './logo.svg';
import React, { useState, useEffect } from "react";
import './App.css';
import Consulta from './components/Consulta.js'

function App() {
  return (
    <div className="consulta-rni" >
      <Consulta/>

    </div>

  );
}

export default App;
